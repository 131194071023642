<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false"
    width="500px">
    <template v-if="dialogVisibletitle === '新增部门' || dialogVisibletitle === '修改部门'">
      <div class="dp-f">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="部门名称" prop='name'>
            <el-input v-model="ruleForm.name" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入部门名称" />
          </el-form-item>
          <el-form-item label="上级部门" prop="parentId">
            <el-tree-select v-model="ruleForm.parentId" :data="state.deptData" :render-after-expand="false"
              check-strictly=true placeholder="请选择上级部门"></el-tree-select>
          </el-form-item>
          <el-form-item label="排序号" prop='sort'>
            <el-input-number v-model="ruleForm.sort" :min="1" :max="999" controls-position="right" :step="1" />
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='52' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='52' CSStype=3 title="取消" @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import {getdeptData} from "@/utils/server/selectdata";
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  deptData: [],//部门下拉数据
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  name: '',//部门名称
  parentId: '',//上级部门
  sort: 1,//排序
})
const rules = reactive({//from 表单校验
  name: [
    { required: true, message: '请输入部门名称', trigger: 'blur' },
  ],
  parentId: [
    { required: true, message: '请选择上级部门', trigger: 'blur' },
  ],
  sort: [
    { required: true, message: '请选择排序', trigger: 'blur' },
  ],
})
// 表单重置
const fromDataReset = (() => {
  objdata_ret(ruleForm)
  ruleForm.sort=1
})
const add = (() => {
  getdeptData().then(res => {
    state.deptData = res
    fromDataReset()
    ruleForm.parentId=res[0].deptId
    dialogloading.value = false
    dialogVisibletitle.value = '新增部门'
    dialogVisible.value = true
    nextTick(() => {
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
})
const editclick = ((row) => {
  getdeptData().then(res => {
    state.deptData = res
    fromDataReset()
    objdata_set(ruleForm, row)
    id.value = row.deptId
    dialogloading.value = false
    dialogVisibletitle.value = '修改部门'
    dialogVisible.value = true
    nextTick(() => {
      unref(ruleFormRef).clearValidate()//清除表单验证结果
    })
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '新增部门') {
        dataGeneration(ruleForm)
      } else if (dialogVisibletitle.value === '修改部门') {
        let ruleFormjson = {
          deptId: id.value,
          ...ruleForm
        }
        dataGeneration(ruleFormjson, '/admin/sys-dept/update')
      }
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/sys-dept/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
  editclick,
});
</script>